import { useState } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Container,
} from "@mui/material";
import { easing } from "maath";
import Visualization from "./Visualization";

export default function Configurator() {
  const [currentVariant, setCurrentVariant] = useState("Default");
  const [variants, setVariants] = useState([]);

  const handleMaterialNames = (names) => {
    setVariants(names);
  };

  return (
    <div
      className="TwoHear-web-configurator"
      style={{ width: "100%", height: "100%", touchAction: "none" }}
    >
      <Container
        maxWidth="lg"
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          sx={{
            width: "100%",
            height: "100%",
          }}
          spacing={2}
        >
          <Grid item xs={12}></Grid>
          <Grid
            item
            xs={6}
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
              }}
            >
              <Visualization
                variant={currentVariant}
                setVariants={handleMaterialNames}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                width: "100%",
                height: "100%",
                borderRadius: "10px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                padding: 2, // Add padding here
                boxSizing: "border-box", // Add this line
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Gehäuse Farbe
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={currentVariant}
                  label="Gehäuse Farbe"
                  onChange={(event) => setCurrentVariant(event.target.value)}
                >
                  <MenuItem value={"case_pink"}>Pink</MenuItem>
                  <MenuItem value={"case_black"}>Schwarz</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
