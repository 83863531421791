import { useCallback, useEffect, useRef, useState } from "react";
import { useLoader, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useGLTF } from "@react-three/drei";

export const Product = ({ variant, setVariants }) => {
  const gltf = useLoader(GLTFLoader, "/models/gehause_left.gltf");
  const scene = gltf.scene;
  const [parser, setParser] = useState(null);
  const [extension, setExtension] = useState(null);

  useEffect(() => {
    const extension = gltf.userData.gltfExtensions["KHR_materials_variants"];
    const variants = extension.variants.map((variant) => variant.name);

    setParser(gltf.parser);
    setExtension(extension);
    setVariants(variants);
  }, [gltf]);

  const selectVariant = useVariant(scene, parser, extension);

  useEffect(() => {
    if (parser && extension) {
      selectVariant(variant);
    }
  }, [parser, extension, variant]);

  return (
    <primitive
      receiveShadow
      castShadow
      object={scene}
      scale={0.1}
      rotation={[-Math.PI / 2, 0, Math.PI / 8]}
    />
  );
};

const useVariant = (scene, parser, extension) => {
  const [variantName, setVariantName] = useState(null);

  const selectVariant = (name) => {
    setVariantName(name);
  };

  useEffect(() => {
    if (!variantName || !parser || !extension) return;
    console.log("variantNames extension: ", extension.variants);
    const variantIndex = extension.variants.findIndex((v) =>
      v.name.includes(variantName)
    );

    console.log("variantIndex:", variantIndex);
    console.log("variantName:", variantName);

    scene.traverse(async (object) => {
      if (!object.isMesh || !object.userData.gltfExtensions) return;

      const meshVariantDef =
        object.userData.gltfExtensions["KHR_materials_variants"];

      if (!meshVariantDef) return;

      if (!object.userData.originalMaterial) {
        object.userData.originalMaterial = object.material;
      }

      const mapping = meshVariantDef.mappings.find((mapping) =>
        mapping.variants.includes(variantIndex)
      );
      console.log("mapping:", mapping);

      if (mapping) {
        object.material = await parser.getDependency(
          "material",
          mapping.material
        );
        console.log("object.material:", object.material);
        parser.assignFinalMaterial(object);
      } else {
        object.material = object.userData.originalMaterial;
      }
    });
  }, [scene, parser, extension, variantName]);

  return selectVariant;
};
