import {
  Center,
  ContactShadows,
  Environment,
  PresentationControls,
} from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { Product } from "./Product";

export default function Visualization({ variant, setVariants }) {
  return (
    <Canvas dpr={[1, 2]} gl={{ preserveDrawingBuffer: true }}>
      <color attach="background" args={["#475657"]} />
      <Environment background={false} blur={0} preset={"warehouse"} />
      <ambientLight intensity={0.5} />
      <Suspense fallback={null}>
        <PresentationControls global zoom={0.5}>
          <Center>
            <Product variant={variant} setVariants={setVariants} />
          </Center>
        </PresentationControls>
        <ContactShadows
          opacity={1}
          scale={50}
          blur={0.8}
          far={20}
          resolution={256}
          color="#000000"
          position={[0, -2, 0]}
        />
      </Suspense>
    </Canvas>
  );
}
