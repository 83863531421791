import {
  ThemeProvider,
  Toolbar,
  createTheme,
  Box,
  AppBar,
  Item,
  Stack,
  Divider,
} from "@mui/material";
import "./App.css";
import Configurator from "./components/Configurator";
import { Suspense } from "react";

function App() {
  const theme = createTheme({
    palette: {
      warning: {
        main: "#393a10", // Drab dark brown
      },
      secondary: {
        main: "#62929e", // Blue (Munsell)
      },
      error: {
        main: "#4a6d7c", // Payne's gray
      },
      info: {
        main: "#c6c5b9", // Silver
      },
      primary: {
        main: "#475657", // Outer space
      },
    },
  });
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Box mb={2}>
          {" "}
          {/* Add this Box component */}
          <AppBar position="sticky">
            <Toolbar>
              <h1>Made in Bremen - Maßgefertigte In-Ear Kopfhörer</h1>
            </Toolbar>
          </AppBar>
        </Box>
        <Stack
          spacing={2}
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              height: {
                xs: 200, // height on extra small screens
                sm: 200, // height on small screens
                md: 400, // height on medium screens
                lg: 400, // height on large screens
                xl: 400, // height on extra large screens
              },
            }}
          >
            <Suspense>
              <Configurator />
            </Suspense>
          </Box>
        </Stack>
      </ThemeProvider>
    </div>
  );
}

export default App;
